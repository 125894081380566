'use client'
import { containerBreakpoints } from '@/components/breakpoints'
import windowSize from "@/components/windowSize"
import CustonButton from '@/components/button'
import { Box, Button, HStack, Image, IconButton, SimpleGrid, Text, VStack, Heading, Fade, Container } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import InnerHtml from './innerHtml'

const PartinersComponent = (props: any) => {
    const { data } = props

    const [current, setCurrent] = useState(0)
    const transition = { exit: { duration: 0.8 }, enter: { duration: 0.8 } }
    const h5Before = {
        position: 'relative',
        content: '""',
        width: '7px',
        height: '7px',
        display: 'block',
        backgroundColor: '#cc0007',
        borderRadius: '50%',
        marginBottom: '16px',
    }

    const { width, height } = windowSize()

    const getBgImage = () => {
        if(width && width >= 768) {
            return data?.ImagemFundoDesktop?.data?.attributes?.url
        }
        return null
    }

    const moveSlider = (direction: number) => {
        let nextPossition = direction > 0
            ? (current >= 2 ? -2 : direction)
            : (current == 0 ? 2 : direction)

        setCurrent(current + nextPossition)
    }

    const getProps = () => {
        return {
            h: 680,
            //pos: {md:'absolute', base: 'relative'},
            //pos: 'relative',
            left: data?.Alinhamento === 'Esquerda' ? 0 : undefined,
            right: data?.Alinhamento === 'Esquerda' ? undefined : 0,
            w: { sm: '100%', md: '50%', base: '100%' }
        }
    }

    const getLink = () => {
        if (data?.PaginaExt) {
            return data?.PaginaExt
        }
        if (data?.PaginaInt?.data) {
            return data?.PaginaInt?.data?.attributes?.Slug
        }
        return '#'
    }

    

    return (
        <Box as="section" pos={'relative'} bg={`url(${getBgImage()})`} className={data?.ClasseCss}>
            <Container minW={containerBreakpoints} >
                <HStack flexWrap={'wrap'} gap={0} flexDirection={data?.Alinhamento == 'Esquerda' ? 'row-reverse' : 'row'}>
                    <Box pos={'relative'} {...getProps()}>

                        {data?.Imagens?.data?.map((item: any, index: number) => {
                            return (
                                <Fade key={item?.id} in={parseInt(current.toString(), 10) == index} style={{
                                    height: '100%',
                                    position: 'absolute',
                                    left: data?.Alinhamento === 'Esquerda' ? 0 : '',
                                    right: data?.Alinhamento === 'Esquerda' ? '' : 0,
                                }} transition={transition}>
                                    <Image h={'100%'} w={'100%'} objectFit={'cover'} alt={item?.Descricao} src={item?.attributes?.url} />
                                </Fade>
                            )
                        })}
                    </Box>
                    <Box w={{ sm: '100%', md: '50%', base: '100%' }} minH={'680'}>
                        <Box marginBottom={'50px'} marginTop={30}>
                            <Heading className={`${data?.ClasseCss}-subtitulo`} as="h5" textTransform={'uppercase'} fontSize={'14px'} letterSpacing={'1.4px'} lineHeight={'1.2'} fontWeight={'500'} margin={'0 0 7px'} position={'relative'} color={'#c4c4c4'} fontFamily={"'Plus Jakarta Sans', sans-serif"} _before={h5Before}>
                                {data?.Subtitulo}
                            </Heading>
                            <Heading className={`${data?.ClasseCss}-titulo`} fontSize={'77px'} letterSpacing={'-2px'} lineHeight={'68px'} margin={'0'} fontWeight={800} fontFamily={"'Plus Jakarta Sans', sans-serif"}>
                                {data?.Titulo}
                            </Heading>
                        </Box>
                        <InnerHtml className={`${data?.ClasseCss}-descricao`} html={data?.Descricao} color={'#999'} fontSize={'17px'} marginTop={0} marginBottom={'1rem'} lineHeight={'1.875'} fontWeight={500} />
                        {data?.TextoLink &&
                            <Box mb='20px'>
                                <CustonButton href={getLink()|| '#'} target={data?.PaginaExt ? "_blank" : ""} className={`${data?.ClasseCss}-botao`}>{data?.TextoLink}</CustonButton>
                            </Box>
                        }
                        {data?.Imagens?.data?.length > 1 &&
                            <HStack alignSelf={'flex-start'} py={10}>
                                <Box rounded={'full'} w={'56px'} h={'56px'} border={'2px solid #ebebeb'} display={'flex'} justifyContent={'center'} alignItems={'center'} onClick={() => moveSlider(-1)} cursor={'pointer'}>
                                    <Box as="span" fontSize={'20px'} fontWeight={500} color={'#ebebeb'}>&lt;</Box>
                                </Box>
                                <Box rounded={'full'} w={'56px'} h={'56px'} border={'2px solid #ebebeb'} display={'flex'} justifyContent={'center'} alignItems={'center'} onClick={() => moveSlider(1)} cursor={'pointer'}>
                                    <Box as="span" fontSize={'20px'} fontWeight={500} color={'#ebebeb'}>&gt;</Box>
                                </Box>
                            </HStack>
                        }
                    </Box>
                </HStack>
            </Container>
        </Box>
    )
}

export default PartinersComponent
