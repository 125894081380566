const columnsBreakpoints = ['100%', '100%', '50%', '25%', '25%', '25%']
const containerBreakpoints = {
    base: '',
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    "2xl": "1313px",
};

export {
    containerBreakpoints,
    columnsBreakpoints
}