'use client'

import { Text, Image, SimpleGrid, Button, VStack, Show, HStack, Box } from "@chakra-ui/react"
import InnerHtml from "./innerHtml"

const SliderPortfolio2 = (props:any) => {
    const { data } = props

    const moveSlider = (direction: number) => {
            
        }

    const ImageComponent = (item:any) => {
        return (
            <Box pos={'relative'}>
                <HStack pos={'absolute'} zIndex={1000} top={0} left={'20px'} alignSelf={'flex-start'} py={10}>
                    <Box rounded={'full'} w={'56px'} h={'56px'} bg={'red.200'} border={'2px solid #ebebeb'} display={'flex'} justifyContent={'center'} alignItems={'center'} onClick={() => moveSlider(-1)} cursor={'pointer'}>
                        <Box as="span" fontSize={'20px'} fontWeight={500} color={'#ebebeb'}>&lt;</Box>
                    </Box>
                    <Box rounded={'full'} w={'56px'} bg={'red.300'} h={'56px'} border={'2px solid #ebebeb'} display={'flex'} justifyContent={'center'} alignItems={'center'} onClick={() => moveSlider(1)} cursor={'pointer'}>
                        <Box as="span" fontSize={'20px'} fontWeight={500} color={'#ebebeb'}>&gt;</Box>
                    </Box>
                </HStack>
                
                <Box style={{height: '100%', position: 'absolute', top: 100, left: 10}}>
                    <Show above="md">
                            <Image rounded={'full'} id="SlidePortifolio2-image"  h={'100%'} objectFit={'cover'}  src={item?.item?.ImagemDesktop?.data?.attributes?.url} alt={item?.alt} />
                    </Show>
                    <Show below="sm">
                            <Image id="SlidePortifolio2-image" w={'100%'} rounded={'full'} 
                            objectFit={'contain'} src={item?.item?.ImagemMobile?.data?.attributes?.url} alt={item?.alt} />
                    </Show>
                </Box>
                
            </Box>
        )
    }

    const TextComponent = (props:any) => {
        const { item } = props
        return (
            <VStack id="SliderPortfolio1-vstack" gap={10} minH={300}>
                <Text id="SliderPortfolio1-title" fontWeight={'bold'} fontSize={22}>{item?.Subtitulo}</Text>
                <Text id="SliderPortfolio1-title" fontWeight={'bold'} fontSize={32}>{item?.Titulo}</Text>
                <InnerHtml id="SliderPortfolio1-desc" fontSize={22} html={item?.Descricao} />
                <Button id="SliderPortfolio1-button" alignSelf={'flex-start'} bg={'rgb(204,0,7)'} color={'white'} rounded={0} py={8} px={10} as={'a'} href={item?.paginaInt||'#'}>{item?.TextoLink}</Button>
            </VStack>
        )
    }
    
    return (
        <Box as={'section'} pos={'relative'}>
            <Box overflow={'hidden'}>
                {data?.Slides.map((item:any, index:number) => {
                    return(
                        <SimpleGrid my={2} key={'slide-'+index} pos={'relative'} bg={'gray.200'} rounded={'md'} p={10} 
                            className="SliderPortfolio2" gap={10} 
                            columns={{
                                sm: 1,
                                md: 2,
                                lg: 2,
                                xl: 2,
                                "2xl": 2,
                            }} w={'full'}>
                        
                            {data?.Alinhamento === 'Esquerda' ? <ImageComponent item={item} />:<TextComponent item={item} />}
                            {data?.Alinhamento === 'Esquerda' ? <TextComponent item={item} />:<ImageComponent item={item} />}
                        </SimpleGrid>
                    )
                }
                )}
            </Box>
        </Box>

    )
}

export default SliderPortfolio2
