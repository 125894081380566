
import HeaderInitComponent from '@/components/header/header-init'
import HeaderMobile from '@/components/header/header-mobile'
import HeaderScrollComponent from '@/components/header/header-scroll'
import { ReactNode } from 'react'

export default function HeaderSlot(props:any):ReactNode {
  const {cabecalho,localizations} = props
  return (
    <>
    <HeaderInitComponent localizations={localizations} data={cabecalho} blogCategories={props?.blogCategories}/>
    <HeaderScrollComponent localizations={localizations} data={cabecalho} blogCategories={props?.blogCategories} />
    <HeaderMobile localizations={localizations} data={cabecalho} blogCategories={props?.blogCategories} />
    </>
  )
}
