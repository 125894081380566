'use client'
import { Text, VStack, Grid, GridItem, Box, Container } from "@chakra-ui/react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import InnerHtml from "./innerHtml"
import { containerBreakpoints } from "@/components/breakpoints"

library.add(fas)

const ConteudoIcone1 = (props: any) => {
    const { data } = props

    const css = {
        'WebkitTextFillColor': '#FFF',
        'WebkitTextStrokeWidth': '1px',
        'WebkitTextStrokeColor': '#cbcbcb'
    }

    const NumberItem = (props: any) => {
        const { item, index } = props
        //return (null)
        return <Text fontSize={'120px'} fontWeight={800} fontFamily={"'Poppins', sans-serif"} css={css}>
            {index}
        </Text>
    }

    const camelToDash = (str: string) => {
        return str.replace(/([A-Z])/g, function ($1) { return "-" + $1.toLowerCase(); });
    }

    const IconComponent = (props: any) => {
        const { item } = props
        //const iconName = require('@fortawesome/free-solid-svg-icons')
        const camelIcon = camelToDash(item.Icone)
        let iconName: any = camelIcon.split('-')
        // remove o primeiro item
        iconName.shift()
        // remove o segundo item
        iconName.shift()
        // remove o terceiro item
        if (iconName[0] == 'reg') iconName.shift()
        iconName = iconName.join('-')
        
        return (
            <FontAwesomeIcon style={{ fontSize: 72 }} color="rgb(204,0,0)" size="xs" icon={["fas", iconName]} />
        )
        return (
            <Box>{item?.Icone}</Box>
        )
        return (
            <FontAwesomeIcon style={{ fontSize: 72 }} color="rgb(204,0,0)" size="xs" icon={item?.Icone} />
        )
    }

    const TextComponent = (props: any) => {
        const { item } = props
        if (!item) return (null)
        
        return (
            <VStack id="ConteudoIcone1-vstack" gap={2}>
                <Text color={'black'} alignSelf={'flex-start'} id="ConteudoIcone1-titulo" fontWeight={'bold'} fontSize={22}>{item?.Titulo}</Text>
                <InnerHtml alignSelf={'flex-start'} id="ConteudoIcone1-descricao" fontSize={14} html={item?.Descricao} />
            </VStack>
        )
    }


    return (
        <Box as='section' className={data?.ClasseCss}>
            <Container maxW={containerBreakpoints}>

                {data?.Icones1.map((item: any, index: number) => {
                    return (
                        <Grid key={`conteudo-icone-${index}`} templateColumns='repeat(8, 1fr)' pos={'relative'} p={2} 
                            className="box-icones" gap={0} w={'full'} borderTop={'1px solid #ebebeb'}>
                            <GridItem colSpan={1} alignSelf={'center'}>
                                {data?.Alinhamento === 'Esquerda' ? <IconComponent item={item} /> : <NumberItem item={item} index={item?.Numero} />}
                            </GridItem>
                            <GridItem colSpan={6} alignSelf={'center'}>
                                <TextComponent item={item} />
                            </GridItem>
                            <GridItem colSpan={1} alignSelf={'center'}>
                                {data?.Alinhamento === 'Esquerda' ? (<NumberItem item={item} index={item?.Numero} />) : <IconComponent item={item} />}
                            </GridItem>
                        </Grid>
                    )
                })}

            </Container>
        </Box>
    )
}

export default ConteudoIcone1
