import { containerBreakpoints } from "@/components/breakpoints"
import CustonButton from "@/components/button"
import { Box, Text, Heading, Container, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Flex } from "@chakra-ui/react"
import InnerHtml from "./innerHtml"

const ConteudoAccordion1 = (props: any) => {
    const { data } = props

    const before = {
        position: 'absolute',
        top: 0,
        content: '""',
        width: '7px',
        height: '7px',
        display: 'block',
        backgroundColor: '#cc0007',
        borderRadius: '50%',
        marginBottom: '16px'
    }
    const beforeLeft = { ...before, ...{ left: 0 } }
    const beforeRight = { ...before, ...{ right: 0 } }

    const getSubtitleDecoration = () => {
        if (data.Alinhamento === 'Direita') {
            return beforeRight
        }
        return beforeLeft
    }

    const getTextAlign = () => {
        if (data.Alinhamento === 'Direita') {
            return 'end'
        }
        return 'start'
    }


    return (
        <Box as="section" className={data?.ClasseCss} w={'100%'}>
            <Container maxW={containerBreakpoints}>
                <Text className={`${data?.ClasseCss}-subtitulo`} as='h5' _before={getSubtitleDecoration()} pos='relative' pt={'16px'} 
                textAlign={getTextAlign()} w={'100%'} fontSize={'14px'} lineHeight={1.2} 
                textTransform={'uppercase'} letterSpacing={'1.4px'} fontWeight={'500'} 
                margin={'0 0 7px'} fontFamily={"'Plus Jakarta Sans', sans-serif"}>{data?.Subtitulo}</Text>
                <Heading className={`${data?.ClasseCss}-titulo`} mb={10} fontSize={'50px'} letterSpacing={'-2px'} textAlign={getTextAlign()} 
                fontWeight={800} fontFamily={"'Plus Jakarta Sans', sans-serif"}>{data?.Titulo}</Heading>
                <InnerHtml className={`${data?.ClasseCss}-descricao`} fontSize={'17px'} lineHeight={'29px'} pb={'10px'} fontWeight={500} 
                textAlign={getTextAlign()} html={data?.Descricao} />
                <Box w={'100%'} mt="5">
                    <Accordion allowToggle>
                        {data?.Accordions.map((item: any, index: number) => {
                            return (
                                <AccordionItem className={`${data?.ClasseCss}-accordion-item`} borderY='none' mb={3} key={`conteudo-accordion-${item?.id}`}>
                                    <AccordionButton borderRadius={'full'} bg={'blackAlpha.100'}>
                                        <Flex justifyContent={'space-between'} alignItems={'center'} w={'full'} flexDir={item?.Alinhamento === 'Direita' ? 'row' : 'row-reverse'}>
                                            <Text as="h3" fontWeight={'bold'} className={`${data?.ClasseCss}-accordion-item-titulo`}>
                                                {item?.Titulo}
                                            </Text>
                                            <AccordionIcon color={'red.500'} />
                                        </Flex>
                                    </AccordionButton>
                                    <AccordionPanel>
                                        <InnerHtml className={`${data?.ClasseCss}-accordion-item-descricao`} align={item?.Alinhamento=='Direita'?'left':'right'} py={4} html=
                                            {item?.Descricao}
                                        />
                                        <Box alignSelf={'flex-start'}>
                                            <CustonButton href={item?.PaginaExt} target={'_blank'} className="conteudo-img1-botao">{item?.TextoLink}</CustonButton>
                                        </Box>
                                    </AccordionPanel>
                                </AccordionItem>
                            )
                        })}
                    </Accordion>
                </Box>
            </Container>
        </Box>
    )
}

export default ConteudoAccordion1