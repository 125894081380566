import { containerBreakpoints } from "@/components/breakpoints"
import CustonButton from "@/components/button"
import { BoxGray, BoxRed } from '@/components/boxes'
import { Text, Image, SimpleGrid, Show, Button, VStack, Box, Container, Heading, AspectRatio, HStack } from "@chakra-ui/react"
import InnerHtml from "./innerHtml"

const ConteudoImg1 = (props: any) => {
    const { data } = props

    const before = {
        position: 'relative',
        content: '""',
        width: '7px',
        height: '7px',
        display: 'block',
        backgroundColor: '#f1f1f1',
        borderRadius: '50%',
        marginBottom: '16px'
    }
    const breakPoints = ['100%', '100%', '100%', '50%']

    const getLink = () => {
        if(data?.PaginaExt) {
            return data?.PaginaExt
        }
        if(data?.PaginaInt?.data) {
            return data?.PaginaInt?.data?.attributes?.Slug
        }
        return '#'
    }

    const ImageComponent = () => {
        return (
            <Box pos={'relative'}>
                <BoxGray />
                    <Show above={'sm'}>
                        <AspectRatio ratio={1}>
                            <Image
                                rounded={'full'}
                                id="ConteudoImg1-image"
                                objectFit='cover'
                                src={data?.ImagemDesktop?.data?.attributes?.url} alt={data?.alt} />
                        </AspectRatio>
                    </Show>

                    <Show below={'sm'}>
                        <AspectRatio ratio={1}>
                            <Image id="ConteudoImg1-image"
                                rounded={'full'}
                                objectFit='cover'
                                src={data?.ImagemMobile?.data?.attributes?.url} alt={data?.alt} />
                        </AspectRatio>

                    </Show>
                <BoxRed />
            </Box>
        )
    }

    const TextComponent = () => {
        return (
            <VStack id="ConteudoImg1-vstack" gap={0} minH={300} mt={'40px'}>
                <Text as='h5' _before={before} 
                className="conteudo-img1-subtitulo"
                textAlign={'start'} w={'100%'} fontSize={'14px'} lineHeight={1.2} textTransform={'uppercase'} letterSpacing={'1.4px'} fontWeight={'500'} margin={'0 0 7px'} fontFamily={"'Plus Jakarta Sans', sans-serif"}>{data?.Subtitulo}</Text>
                <Heading 
                className="conteudo-img1-titulo"
                fontSize={'50px'} letterSpacing={'-2px'} fontWeight={800} fontFamily={"'Plus Jakarta Sans', sans-serif"}>{data?.Titulo}</Heading>
                <InnerHtml 
                className="conteudo-img1-descricao"
                fontSize={'17px'} lineHeight={'29px'} pb={'10px'} fontWeight={500} html={data?.Descricao} />

                {data?.TextoLink && <Box alignSelf={'flex-start'}>
                    <CustonButton href={getLink()|| '#'} target={data?.PaginaExt ? "_blank" : ""} className="conteudo-img1-botao">{data?.TextoLink}</CustonButton>
                </Box>}
            </VStack>
        )
    }

    return (

        <Box as={'section'} position={'relative'} padding={'0 0 113px'} className={data?.ClasseCss}>
            <Container maxW={containerBreakpoints}>
                <SimpleGrid pos={'relative'} gap={10} columns={{
                    sm: 1,
                    md: 1,
                    lg: 2
                }} w={'full'}>

                    {data?.Alinhamento === 'Esquerda' ? <ImageComponent /> : <TextComponent />}
                    {data?.Alinhamento === 'Esquerda' ? <TextComponent /> : <ImageComponent />}
                </SimpleGrid>
            </Container>
            <Box w={{base:150, sm: 150, md: 250}} h={{base: 150, sm: 150, md: 250}} position={'absolute'} bottom={0} right={0} backgroundSize={'contain!important'} background={'url(https://dr7dcgej7t392.cloudfront.net/uploads/bg_arco_digital_10d88c49ff.webp) no-repeat'} />
        </Box>
    )
}

export default ConteudoImg1
