import { createIcon } from "@chakra-ui/icon";

const PlayIcon = createIcon({
    displayName: 'PlayIcon',
    viewBox: '0 0 800 800',
    path: (
        <path
            fill="currentColor"
            d="M400,87.5C227.4,87.5,87.5,227.4,87.5,400S227.4,712.5,400,712.5S712.5,572.6,712.5,400
                C712.3,227.5,572.5,87.7,400,87.5z M400,687.5c-158.8,0-287.5-128.7-287.5-287.5S241.2,112.5,400,112.5S687.5,241.2,687.5,400
                C687.3,558.7,558.7,687.3,400,687.5z M506.9,389.6l-150-100c-5.7-3.8-13.5-2.3-17.3,3.5c-1.4,2.1-2.1,4.5-2.1,6.9v200
                c0,6.9,5.6,12.5,12.5,12.5c2.5,0,4.9-0.7,6.9-2.1l150-100c5.7-3.8,7.3-11.6,3.5-17.3C509.5,391.7,508.3,390.5,506.9,389.6
                L506.9,389.6z M362.5,476.6V323.4l115,76.6L362.5,476.6z"
        />
    )

})

export default PlayIcon