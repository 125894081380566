import { containerBreakpoints } from "@/components/breakpoints"
import CustonButton from "@/components/button"
import { Text, VStack, Box, HStack, Button, Flex, Spacer, Heading, Container } from "@chakra-ui/react"

const HeadingText1 = (props: any) => {
    const { data } = props
    const before = {
        position: 'absolute',
        top: 0,
        content: '""',
        width: '7px',
        height: '7px',
        display: 'block',
        backgroundColor: '#cc0007',
        borderRadius: '50%',
        marginBottom: '16px'
    }
    const beforeCenter = { ...before, ...{ left: '50%' } }
    const beforeLeft = { ...before, ...{ left: 0 } }
    const beforeRight = { ...before, ...{ right: 0 } }

    const Center = () => {
        return (
            <Flex id="HeadingText1-hstack" gap={2}>
                <VStack id="HeadingText1-vstack" gap={2} alignContent={'center'} justifyContent={'center'} w={'full'} >
                    <Text as='h5' _before={beforeCenter} paddingTop={'16px'} pos={'relative'} textAlign={'start'} fontSize={'14px'} lineHeight={1.2} textTransform={'uppercase'} letterSpacing={'1.4px'} fontWeight={'500'} margin={'0 0 7px'} fontFamily={"'Plus Jakarta Sans', sans-serif"}>
                        {data?.Subtitulo}
                    </Text>
                    <Heading fontSize={'50px'} letterSpacing={'-2px'} fontWeight={800} fontFamily={"'Plus Jakarta Sans', sans-serif"}>{data?.Titulo}</Heading>
                    <Box mt={'20px'}>
                        {data?.TextoLink &&
                            <CustonButton className={`${data?.ClasseCss}-interno-botao`} href={data?.PaginaExt || data?.PaginaInt?.data?.attributes?.Slug} target={data?.PaginaExt ? "_blank" : ""}>{data?.TextoLink}</CustonButton>
                        }
                    </Box>
                </VStack>
            </Flex>
        )
    }

    const Right = () => {
        return (
            <Flex id="HeadingText1-hstack" gap={2} flexDirection={'column'}>
                <Text as='h5' _before={beforeRight} paddingTop={'16px'} pos={'relative'} textAlign={'end'} fontSize={'14px'} lineHeight={1.2} textTransform={'uppercase'} letterSpacing={'1.4px'} fontWeight={'500'} margin={'0 0 7px'} fontFamily={"'Plus Jakarta Sans', sans-serif"}>
                    {data?.Subtitulo}
                </Text>
                <Flex flexWrap={{sm:'wrap', md: 'nowrap'}}>
                    <Box w={{sm: '100%',md:'100%'}}>
                        {data?.TextoLink &&
                            <CustonButton className={`${data?.ClasseCss}-interno-botao`} href={data?.PaginaExt || data?.PaginaInt?.data?.attributes?.Slug} target={data?.PaginaExt ? "_blank" : ""}>{data?.TextoLink}</CustonButton>
                        }
                    </Box>
                    <VStack id="HeadingText1-vstack" gap={2} alignItems={'end'} w={{sm: '100%',md:'100%'}}>
                        <Heading fontSize={'50px'} letterSpacing={'-2px'} fontWeight={800} fontFamily={"'Plus Jakarta Sans', sans-serif"}>{data?.Titulo}</Heading>
                    </VStack>
                </Flex>
            </Flex>
        )
    }

    const Left = () => {
        return (
            <Flex id="HeadingText1-hstack" gap={2} flexDirection={'column'}>
                <Text as='h5' _before={beforeLeft} paddingTop={'16px'} pos={'relative'} textAlign={'start'} fontSize={'14px'} lineHeight={1.2} textTransform={'uppercase'} letterSpacing={'1.4px'} fontWeight={'500'} margin={'0 0 7px'} fontFamily={"'Plus Jakarta Sans', sans-serif"}>
                    {data?.Subtitulo}
                </Text>
                <Flex flexWrap={{base: 'wrap', sm:'wrap', md: 'nowrap'}} justifyContent={'space-between'}>
                    <VStack id="HeadingText1-vstack" gap={2} alignItems={'start'} w={{sm: '100%', md: 'auto'}}>
                        <Heading fontSize={'50px'} letterSpacing={'-2px'} fontWeight={800} fontFamily={"'Plus Jakarta Sans', sans-serif"}>{data?.Titulo}</Heading>
                    </VStack>
                    
                    <Box w={{sm: '100%', md: 'auto'}}>
                        {data?.TextoLink &&
                            <CustonButton className={`${data?.ClasseCss}-interno-botao`} href={data?.PaginaExt || data?.PaginaInt?.data?.attributes?.Slug} target={data?.PaginaExt ? "_blank" : ""}>{data?.TextoLink}</CustonButton>
                        }
                    </Box>
                </Flex>
            </Flex>
        )
    }

    return (
        <Box as={'section'} 
            pos={'relative'} 
            p={2}
            mb={'30px'}
            className={data?.ClassName} 
            gap={10} w={'full'}>
            <Container maxW={containerBreakpoints}>
                {data?.Alinhamento == 'Esquerda' && <Left />}
                {data?.Alinhamento == 'Direita' && <Right />}
                {data?.Alinhamento == 'Centro' && <Center />}
            </Container>
        </Box>
    )
}

export default HeadingText1
