import { containerBreakpoints } from "@/components/breakpoints"
import CustonButton from "@/components/button"
import { BoxGray, BoxRed } from '@/components/boxes'
import { Text, Image, SimpleGrid, Show, Button, VStack, Box, Container, Heading, AspectRatio, HStack } from "@chakra-ui/react"
import InnerHtml from "./innerHtml"

const ConteudoInternoAba = (props: any) => {
    const { data, parentClass } = props

    const before = {
        position: 'relative',
        content: '""',
        width: '7px',
        height: '7px',
        display: 'block',
        backgroundColor: '#cc0007',
        borderRadius: '50%',
        marginBottom: '16px'
    }
    const breackPoints = ['100%', '100%', '100%', '50%']

    const ImageComponent = () => {
        return (
            <>
                <AspectRatio ratio={1}>
                    <Image id="ConteudoImg1-image"

                        objectFit='cover'
                        src={data?.Imagem?.data?.attributes?.url} alt={data?.alt} />
                </AspectRatio>
            </>
        )
    }

    const TextComponent = () => {
        return (
            <VStack id="ConteudoImg1-vstack" gap={0} minH={300} mt={'40px'}>
                <Text className={`${parentClass}-interno-subtitulo`} as='h5' _before={before} textAlign={'start'} w={'100%'} fontSize={'14px'} lineHeight={1.2} textTransform={'uppercase'} letterSpacing={'1.4px'} fontWeight={'500'} margin={'0 0 7px'} fontFamily={"'Plus Jakarta Sans', sans-serif"}>{data?.Subtitulo}</Text>
                <Heading className={`${parentClass}-interno-titulo`} w={'full'} fontSize={'50px'} letterSpacing={'-2px'} fontWeight={800} fontFamily={"'Plus Jakarta Sans', sans-serif"}>{data?.Titulo}</Heading>
                <InnerHtml className={`${parentClass}-interno-descricao`} fontSize={'17px'} lineHeight={'29px'} pb={'10px'} fontWeight={500} html={data?.Descricao} />

                {data?.TextoLink && <Box alignSelf={'flex-start'}>
                    <CustonButton className={`${parentClass}-interno-botao`} href={data?.PaginaExt || data?.PaginaInt?.data?.attributes?.Slug} target={data?.PaginaExt ? "_blank" : ""}>{data?.TextoLink}</CustonButton>
                </Box>}
            </VStack>
        )
    }


    return (
        <SimpleGrid pos={'relative'} className={data?.ClassName} gap={10} columns={{
            sm: 1,
            md: 1,
            lg: 2
        }} w={'full'}>

            {data?.Alinhamento === 'Esquerda' ? <ImageComponent /> : <TextComponent />}
            {data?.Alinhamento === 'Esquerda' ? <TextComponent /> : <ImageComponent />}
        </SimpleGrid>
    )
}

export default ConteudoInternoAba
