import { containerBreakpoints } from "@/components/breakpoints"
import windowSize from "@/components/windowSize"
import CustonButton from "@/components/button"
import { BoxGray, BoxRed } from '@/components/boxes'
import { Text, Image, SimpleGrid, Show, Button, VStack, Box, Container, Heading, AspectRatio, HStack } from "@chakra-ui/react"
import InnerHtml from "./innerHtml"

const ConteudoImg2 = (props: any) => {
    const { data } = props
    const breakPoints = ['100%', '100%', '100%', '50%']
    const h5Before = {
        position: 'relative',
        content: '""',
        width: '7px',
        height: '7px',
        display: 'block',
        backgroundColor: '#cc0007',
        borderRadius: '50%',
        marginBottom: '16px',
    }

    const { width, height } = windowSize()

    const getBgImage = () => {
        if(width && width >= 768) {
            return data?.ImagemFundoDesktop?.data?.attributes?.url
        }
        return null
    }

    const getLink = () => {
        if(data?.PaginaExt) {
            return data?.PaginaExt
        }
        if(data?.PaginaInt?.data) {
            return data?.PaginaInt?.data?.attributes?.Slug
        }
        return '#'
    }

    const TextComponent = () => {
        return (
            <VStack id="image2-vstack" gap={0} minH={300} justifySelf={'end'}>
                <Text _before={h5Before} as='h5' className={`${data?.ClasseCss}-subtitulo`} textAlign={'start'} w={'100%'} fontSize={'14px'} lineHeight={1.2} textTransform={'uppercase'} letterSpacing={'1.4px'} fontWeight={'500'} margin={'0 0 7px'} fontFamily={"'Plus Jakarta Sans', sans-serif"}>{data?.Subtitulo}</Text>
                <Heading className={`${data?.ClasseCss}-titulo`} w={'full'} fontSize={'50px'} letterSpacing={'-2px'} fontWeight={800} fontFamily={"'Plus Jakarta Sans', sans-serif"}>{data?.Titulo}</Heading>
                <InnerHtml className={`${data?.ClasseCss}-descricao`} fontSize={'17px'} lineHeight={'29px'} pb={'10px'} fontWeight={500} html={data?.Descricao} />
                <Box alignSelf={'flex-start'}>
                {data?.TextoLink && <Box alignSelf={'flex-start'}>
                    <CustonButton href={getLink()|| '#'} target={data?.PaginaExt ? "_blank" : ""} className="conteudo-img1-botao">{data?.TextoLink}</CustonButton>
                </Box>}
                </Box>
            </VStack>
        )
    }

    const getLeftAlign = () => {
        return <>
            <Box paddingX={15} flexShrink={0} minH={500}>
                <TextComponent />
            </Box>
            <Box flexShrink={0} position={'relative'} marginX={-4} marginBottom={'-113px'}>
                <Show below="md">
                    <Image alt="" src={data?.ImagemFundoMobile?.data?.attributes?.url} />
                </Show>
            </Box>
        </>
    }
    const getRightAlign = () => {
        return <>
            <Box flexShrink={0} position={'relative'} marginX={-4} marginTop={'-113px'}>
                <Show below="md">
                    <Image alt="" src={data?.ImagemFundoMobile?.data?.attributes?.url} />
                </Show>
            </Box>
            <Box marginTop={{base: 10, sm:10, md: 0, lg: 0, xl: 0, '2xl': 0}} paddingX={15} flexShrink={0} minH={500}>
                <TextComponent />
            </Box>
        </>
    }


    return (
        <Box w={'100%'}
            height={'auto'}
            as={'section'}
            position={'relative'}
            paddingY={'113px'}
            bgRepeat={'no-repeat'}
            bgSize={'cover'}
            bgImage={getBgImage()}
            objectFit={'cover'}
            className={data?.ClasseCss}>
            <Container maxW={containerBreakpoints}>
                {/*
                <Box display={'flex'} flexWrap={'wrap'} flexDir={ data.Alinhamento === 'Esquerda' ? 'row' : 'row-reverse'}>
                    <Box paddingX={15} w={breakPoints} flexShrink={0} minH={500}>
                        <TextComponent />
                    </Box>
                    <Box w={breakPoints} flexShrink={0} position={'relative'} marginX={-4} marginBottom={'-113px'}>
                        <Show below="md">
                            <Image src={data?.ImagemFundoMobile?.data?.attributes?.url} />
                        </Show>
                    </Box>
                </Box>
                 */}
                <SimpleGrid flex={'0 0 auto'} w={'100%'} gridAutoFlow={'reverse'} columns={{
                    sm: 1,
                    md: 2,
                    lg: 2,
                    xl: 2,
                    "2xl": 2,
                }} >
                    {data?.Alinhamento === 'Esquerda' && getLeftAlign()}
                    {data?.Alinhamento === 'Direita' && getRightAlign()}
                </SimpleGrid>
            </Container>
        </Box>
    )
}

export default ConteudoImg2
