import { Text, VStack, Image, HStack, Box, Container, Heading, Grid, GridItem, Fade, ScaleFade, SlideFade, Collapse, Slide, Show } from "@chakra-ui/react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { containerBreakpoints } from "@/components/breakpoints"
import { px } from "framer-motion"
import React, { useState } from "react"
import ConteudoIconeItem from "./ConteudoIconeItem"

const ConteudoIcone3 = (props: any) => {
    const { data } = props
    const [currentGroup, setCurrentGroup] = useState(0)
    const h5Before = {
        position: 'relative',
        content: '""',
        width: '7px',
        height: '7px',
        display: 'block',
        backgroundColor: '#cc0007',
        borderRadius: '50%',
        marginBottom: '16px',
    }

    const moveSlider = (direction: number) => {
        let nextPossition = direction > 0
            ? (currentGroup >= chunkArrayInGroups(data.Icones3, 3).length - 1 ? -1 : direction)
            : (currentGroup == 0 ? chunkArrayInGroups(data.Icones3, 3).length - 1 : direction)

        setCurrentGroup(currentGroup + nextPossition)

    }

    const chunkArrayInGroups = (arr: [], size: number) => {
        let result = [];
        let pos = 0;
        while (pos < arr.length) {
            result.push(arr.slice(pos, pos + size));
            pos += size;
        }
        return result;
    }

    const getVisibility = (indexGroup: number) => {
        return currentGroup == indexGroup ? 'inherit' : 'none'
    }

    return (
        <Box as="section" pos={'relative'} bg={'rgb(247,247,247)'} py={10} className={data?.ClasseCss}>
            <Container minW={containerBreakpoints}>
                <Heading className={`${data?.ClasseCss}-subtitulo`} as="h5" textTransform={'uppercase'} fontSize={'14px'} letterSpacing={'1.4px'} lineHeight={'1.2'} fontWeight={'500'} margin={'0 0 7px'} position={'relative'} color={'#c4c4c4'} fontFamily={"'Plus Jakarta Sans', sans-serif"} _before={h5Before}>
                    {data?.Subtitulo}
                </Heading>
                <Heading className={`${data?.ClasseCss}-titulo`} fontSize={'77px'} letterSpacing={'-2px'} lineHeight={'68px'} margin={'0'} fontWeight={800} fontFamily={"'Plus Jakarta Sans', sans-serif"}>
                    {data?.Titulo}
                </Heading>
                <Show below="sm">
                    <Grid templateColumns='repeat(1, 1fr)'>
                        {data?.Icones3.map((icone: any, index: number) => {
                            return (
                                <GridItem key={`icon-item-sm-${icone.id}`} className={`${data?.ClasseCss}-item-icone`} >
                                    <ConteudoIconeItem data={icone} alinhamento={data?.Alinhamento} />
                                </GridItem>
                            )
                        })}
                    </Grid>
                </Show>
                <Show above="md">
                    <HStack justifyContent={'space-between'}>
                        <Box>
                            <Box
                                onClick={() => moveSlider(-1)}
                                rounded={'full'}
                                w={'56px'}
                                h={'56px'}
                                border={'2px solid #808080'}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                cursor={'pointer'}>
                                <Box as="span" fontSize={'20px'} fontWeight={500} color={'#808080'}>&lt;</Box>
                            </Box>
                        </Box>

                        <Grid autoFlow={'column'} gridTemplateColumns={'repeat(1, 100%)'} overflow={'hidden'} my={6}>
                            {chunkArrayInGroups(data?.Icones3, 3).map((group: any, indexGroup: number) => {
                                return (
                                    <Grid templateColumns='repeat(3, 1fr)' key={`icon-group-${indexGroup}`} display={getVisibility(indexGroup)}>
                                        {group.map((icone: any, index: number) => {
                                            return (
                                                <GridItem key={`icon-item-${icone.id}`} >
                                                    <Fade in={currentGroup == indexGroup} >
                                                        <ConteudoIconeItem data={icone} alinhamento={data?.Alinhamento} />
                                                    </Fade>
                                                </GridItem>
                                            )
                                        })}
                                    </Grid>
                                )
                            })}
                        </Grid>
                        <Box>
                            <Box onClick={() => moveSlider(1)}
                                rounded={'full'}
                                w={'56px'}
                                h={'56px'}
                                border={'2px solid #808080'}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                cursor={'pointer'}>
                                <Box as="span" fontSize={'20px'} fontWeight={500} color={'#808080'}>&gt;</Box>
                            </Box>
                        </Box>
                    </HStack>
                </Show>
            </Container>
        </Box>
    )
}

export default ConteudoIcone3
