'use client'
import { containerBreakpoints } from '@/components/breakpoints'
import CustonButton from '@/components/button'
import { Box, Button, HStack, Image, IconButton, SimpleGrid, Text, VStack, Heading, Fade, Container, Show, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, AspectRatio } from '@chakra-ui/react'
import { FaPlay, FaPlayCircle, FaRegPlayCircle } from "react-icons/fa"

import InnerHtml from './innerHtml'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { BoxGray } from '@/components/boxes'
import PlayIcon from '@/components/PlayIcon'

const ConteudoVideoSlider = (props: any) => {
    const { data } = props
    const { isOpen, onOpen, onClose } = useDisclosure()
    const containerRef = useRef<any>()
    const [sliderWidth, setSliderWidth] = useState(0)

    const [current, setCurrent] = useState(0)
    const transition = { exit: { duration: 0.8 }, enter: { duration: 0.8 } }
    const h5Before = {
        position: 'relative',
        content: '""',
        width: '7px',
        height: '7px',
        display: 'block',
        backgroundColor: '#cc0007',
        borderRadius: '50%',
        marginBottom: '16px',
    }

    const moveSlider = (direction: number) => {
        // todo: verificar o código para pegar o tamanho do array Slides
        let nextPossition = direction > 0
            ? (current >= data?.Slides.length - 1 ? -1 : direction)
            : (current == 0 ? data?.Slides.length - 1 : direction)
        
        setCurrent(current + nextPossition)
    }

    const showVideoModal = () => {
        onOpen()
    }

    const _after = {
        position: 'absolute',
        //filter: 'grayscale(100%)',
        zIndex: 2,
        top: 0,
        right: 0,
        width: '50%',
        height: '130%',
        content: `url(/images/chanfrado.svg)`
    }
    useEffect(() => {
        //console.log('ref->', containerRef)
    }, [containerRef])

    useLayoutEffect(() => {
        const fnResize = () => {
            setSliderWidth(containerRef.current?.getBoundingClientRect().width)
        }
        window.addEventListener('resize', fnResize)
        return () => window.removeEventListener('resize', fnResize)
    }, [])



    const goToSlide = () => {
        const url = data?.Slides[current]?.PaginaExterna ? data?.Slides[current]?.PaginaExterna : data?.Slides[current]?.PaginaInterna?.data?.attributes?.url
        if (data?.Slides[current]?.PaginaExterna) {
            window.open(url, '_blank')
        } else {
            location.href = url
        }
    }
    return (
        <Box className={data?.ClasseCss} as="section" pos={'relative'} bg={'gray.100'} display={'flex'} flexWrap={'wrap'} minH={680}>
            <Modal size={'6xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Image alt="" src="https://dr7dcgej7t392.cloudfront.net/uploads/logo_brasal_30fc5c32a2.png" h={'30px'} />
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody p={0}>
                        {data?.Slides[current]?.VideoLocal?.data &&
                            <video src={data?.Slides[current]?.VideoLocal} controls width="100%" height="auto" />
                        }
                        {data?.Slides[current]?.VideoYoutubeUrl &&
                            <AspectRatio height={'100vh'}>
                                <iframe
                                    title={data?.Slides[current]?.Subtitulo}
                                    src={data?.Slides[current]?.VideoYoutubeUrl}
                                    allowFullScreen
                                />
                            </AspectRatio>
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Box className="bg-div-video" zIndex={4} h={'100%'} pos={'absolute'} top={0} overflow={'hidden'} left={0} w={{ sm: '100%', md: '50%', base: '100%' }}>
                <BoxGray
                    w={'100px'}
                    h={'100px'}
                    top={10}
                    right={10}
                    left={null}
                    border={'15px solid #d5dadf'}
                    backgroundColor={'gray.100'} />
                {data?.Slides.map((item: any, index: number) => {
                    return (
                        <Box key={`vid-slider-${item?.id}`}
                            className={`${data?.ClasseCss}-slide-item`}
                            w={'100%'}
                            h={'100%'}
                            display={'flex'}
                            position={'absolute'}>
                            <Fade in={parseInt(current.toString(), 10) == index} transition={transition}>
                                <IconButton onClick={() => showVideoModal()}
                                    color={'white'}
                                    colorScheme="white"
                                    pos={'absolute'}
                                    zIndex={5}
                                    fontSize={90}
                                    top={'50%'}
                                    left={'50%'}
                                    transform={'translate(-50%, -50%)'}
                                    variant={'ghost'}
                                    className="icon-play-video"
                                    aria-label="Play"
                                    icon={<PlayIcon />}
                                    size="xs" />

                                <Image
                                    h={'100%'}
                                    w={'100%'}
                                    objectFit={'cover'}
                                    className="img-bg-video"
                                    borderRadius={'47% 53% 100% 0% / 0% 100%'}
                                    alt={item?.desc} src={item?.ImagemFundoVideo?.data?.attributes?.url} />

                            </Fade>
                        </Box>
                    )
                })}
            </Box>
            <Container className="conteudo-vid-slider-container" zIndex={0} h={'100%'} minW={containerBreakpoints} position={'relative'} ref={containerRef}>

                <HStack h={'100%'} flexWrap={'wrap'} gap={0} flexDirection={data?.Slides[current]?.Alinhamento == 'Esquerda' ? 'row-reverse' : 'row'} position={'relative'}>

                    <Box w={{ sm: '100%', md: '50%', base: '100%' }} h={'100%'} gap={4}>
                        <Box marginBottom={'50px'} marginTop={30}>
                            <Heading className={`${data?.ClasseCss}-subtitulo`} as="h5" textTransform={'uppercase'} fontSize={'14px'} letterSpacing={'1.4px'} lineHeight={'1.2'} fontWeight={'500'} margin={'0 0 7px'} position={'relative'} color={'#c4c4c4'} fontFamily={"'Plus Jakarta Sans', sans-serif"} _before={h5Before}>
                                {data?.Slides[current]?.Subtitulo}
                            </Heading>
                            <Heading className={`${data?.ClasseCss}-titulo`} fontSize={'77px'} letterSpacing={'-2px'} lineHeight={'68px'} margin={'0'} fontWeight={800} fontFamily={"'Plus Jakarta Sans', sans-serif"}>
                                {data?.Slides[current]?.Titulo}
                            </Heading>
                        </Box>
                        <InnerHtml  className={`${data?.ClasseCss}-descricao`} html={data?.Slides[current]?.Descricao} color={'#333'} fontSize={'17px'} marginTop={0} marginBottom={'1rem'} lineHeight={'1.875'} fontWeight={500} />
                        {data?.Slides[current]?.TextoLink &&
                            
                            <CustonButton className={`${data?.ClasseCss}-interno-botao`} href={data?.Slides[current]?.PaginaExt || data?.Slides[current]?.PaginaInt?.data?.attributes?.Slug} target={data?.Slides[current]?.PaginaExt ? "_blank" : ""}>{data?.Slides[current]?.TextoLink}</CustonButton>
                        }
                        <HStack alignSelf={'flex-start'} py={10}  className={`${data?.ClasseCss}-slider-buttons`}>
                            <Box rounded={'full'} w={'56px'} h={'56px'} border={'2px solid #808080'} display={'flex'} justifyContent={'center'} alignItems={'center'} onClick={() => moveSlider(-1)} cursor={'pointer'}>
                                <Box as="span" fontSize={'20px'} fontWeight={500} color={'#808080'}>&lt;</Box>
                            </Box>
                            <Box rounded={'full'} w={'56px'} h={'56px'} border={'2px solid #808080'} display={'flex'} justifyContent={'center'} alignItems={'center'} onClick={() => moveSlider(1)} cursor={'pointer'}>
                                <Box as="span" fontSize={'20px'} fontWeight={500} color={'#808080'}>&gt;</Box>
                            </Box>
                        </HStack>
                    </Box>
                </HStack>
            </Container>
        </Box>
    )
}

export default ConteudoVideoSlider
