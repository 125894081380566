'use client'

import CustonButton from "@/components/button"
import { Text, Image, SimpleGrid, Button, VStack, Show, HStack, Box, Fade, Heading } from "@chakra-ui/react"
import InnerHtml from "./innerHtml"
import { useState } from "react"

const SliderPortfolio1 = (props:any) => {
    const { data, parentClass } = props
    const transition = {exit: {duration: 0.8}, enter: {duration: 0.8}}
    const [current, setCurrent] = useState(0)
    const before = {
        position: 'relative',
        content: '""',
        width: '7px',
        height: '7px',
        display: 'block',
        backgroundColor: '#cc0007',
        borderRadius: '50%',
        marginBottom: '16px'
    }

    const moveSlider = (direction: number) => {
        let nextPossition = direction > 0 
            ? (current >= (data.imagens.length-1) ? data.imagens.length-1 : direction)
            : (current == 0 ? 2 : direction)
        
        setCurrent(current + nextPossition)
    }

    const ImageComponent = () => {
        return (
            <Box pos={'relative'}>
                <HStack pos={'absolute'} zIndex={1000} top={0} left={'20px'} alignSelf={'flex-start'} py={10}>
                    <Box 
                        rounded={'full'} 
                        w={'56px'} 
                        h={'56px'} 
                        borderWidth={'2px'}
                        borderColor={'blackAlpha.300'}
                        display={'flex'} 
                        justifyContent={'center'} 
                        alignItems={'center'}
                        cursor={'pointer'}
                        onClick={() => moveSlider(-1)}>
                        <Box as="span" fontSize={'20px'} fontWeight={500} color={'#ebebeb'}>&lt;</Box>
                    </Box>
                    <Box 
                        rounded={'full'} 
                        w={'56px'} 
                        h={'56px'} 
                        borderWidth={'2px'}
                        borderColor={'blackAlpha.300'}
                        display={'flex'} 
                        justifyContent={'center'} 
                        alignItems={'center'}
                        cursor={'pointer'}
                        onClick={() => moveSlider(1)} >
                        <Box as="span" fontSize={'20px'} fontWeight={500} color={'#ebebeb'}>&gt;</Box>
                    </Box>
                </HStack>
                {data?.Imagens?.data.map((item:any, index:number) => {
                    return (
                        <Box style={{height: '100%', position: 'absolute', top: 100, left: 10}} key={'image'+index}>
                            <Show above="md">
                                <Fade key={item?.id} in={parseInt(current.toString(), 10) == index} transition={transition}>
                                    <Image rounded={'full'} key={'image-'+index} id="SlidePortfolio1-image"  h={'100%'} objectFit={'cover'}  src={item?.attributes?.url} alt={item?.alt} />
                                </Fade>
                            </Show>
                            <Show below="sm">
                                <Fade key={item?.id} in={parseInt(current.toString(), 10) == index} transition={transition}>
                                    <Image key={'image-'+index} id="SlidePortfolio1-image" w={'100%'} rounded={'full'} 
                                    objectFit={'contain'} src={item?.attributes?.url} alt={item?.alt} />
                                </Fade>
                            </Show>
                        </Box>
                    )
                })}
            </Box>
        )
    }

    const TextComponent = () => {
        return (
            <VStack className={`${data?.ClasseCss}-vstack`} gap={0} minH={300} pt={'40px'}>
                <Text as='h5' className={`${data?.ClasseCss}-subtitulo`} _before={before} w='100%' alignSelf={'flex-start'} id="SliderPortfolio1-title" fontSize={'14px'} lineHeight={1.2} textTransform={'uppercase'} letterSpacing={'1.4px'} fontWeight={'500'} margin={'0 0 7px'} fontFamily={"'Plus Jakarta Sans', sans-serif"}>
                    {data?.Subtitulo}
                </Text>
                <Heading className={`${data?.ClasseCss}-titulo`} w='100%' mb={'15px'} fontSize={'50px'} letterSpacing={'-2px'} fontWeight={800} fontFamily={"'Plus Jakarta Sans', sans-serif"}>
                    {data?.Titulo}
                </Heading>
                <InnerHtml w='full' className={`${data?.ClasseCss}-subtitulo-descricao`} fontSize={'17px'} lineHeight={'29px'} pb={'10px'} fontWeight={500} html=
                    {data?.Descricao}
                />
                {data?.TextoLink &&
                    <Box mb='20px'>
                        <CustonButton className={`${data?.ClasseCss}-interno-botao`} href={data?.PaginaExt || data?.PaginaInt?.data?.attributes?.Slug} target={data?.PaginaExt ? "_blank" : ""}>{data?.TextoLink}</CustonButton>
                    </Box>
                }
            </VStack>
        )
    }
    
    return (
        <SimpleGrid as={'section'} pos={'relative'} rounded={'md'} p={10} 
        className={'SliderPortfolio1 '+data?.ClasseCss} gap={10} 
        columns={{
            sm: 1,
            md: 2,
            lg: 2,
            xl: 2,
            "2xl": 2,
        }} >
            <Text color={'gray'} fontWeight={'bold'} pos={'absolute'} top={2} right={2}>SliderPortfolio1</Text>
            {data?.Alinhamento === 'Esquerda' ? <ImageComponent />:<TextComponent />}
            {data?.Alinhamento === 'Esqierda' ? <TextComponent />:<ImageComponent />}
        </SimpleGrid>
    )
}

export default SliderPortfolio1
