'use client'
import { Text, Image, SimpleGrid, VStack, Show, HStack, Box, Modal, IconButton, Center, ModalBody, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Heading, Container, AspectRatio } from "@chakra-ui/react"
import { useState } from "react"
import { FaPlay, FaPlayCircle, FaRegPlayCircle } from "react-icons/fa"
import CustonButton from '@/components/button'
import InnerHtml from "./innerHtml"
import { containerBreakpoints } from "@/components/breakpoints"
import PlayIcon from "@/components/PlayIcon"

const ConteudoVid1 = (props: any) => {
    const { data, parentClass } = props

    const { isOpen, onOpen, onClose } = useDisclosure()
    const before = {
        position: 'relative',
        content: '""',
        width: '7px',
        height: '7px',
        display: 'block',
        backgroundColor: '#cc0007',
        borderRadius: '50%',
        marginBottom: '16px'
    }

    const showVideoModal = () => {
        onOpen()
    }

    const ImageComponent = () => {
        return (
            <Box pos={'relative'} >
                <Box pos={'relative'}>
                    <IconButton onClick={() => showVideoModal()}
                        color={'white'}
                        colorScheme="white"
                        pos={'absolute'}
                        fontSize={90}
                        top={'50%'}
                        left={'50%'}
                        transform={'translate(-50%, -50%)'}
                        variant={'ghost'}
                        aria-label="PlayIcon"
                        size="lg" />

                    <Image
                        id="ConteudoVid1-image"
                        className="conteudo-vid1-imagem-fundo"
                        h={'100%'}
                        objectFit={'cover'}
                        src={data?.ImagemFundoVideo?.data?.attributes?.url}
                        alt={data?.alt} />

                </Box>
            </Box>
        )
    }

    const TextComponent = () => {
        return (
            <VStack className={`${data?.ClasseCss}-vstack`} gap={0} minH={300} pt={'40px'}>
                <Text as='h5' className={`${data?.ClasseCss}-subtitulo`} _before={before} w='100%' alignSelf={'flex-start'} id="SliderPortfolio1-title" fontSize={'14px'} lineHeight={1.2} textTransform={'uppercase'} letterSpacing={'1.4px'} fontWeight={'500'} margin={'0 0 7px'} fontFamily={"'Plus Jakarta Sans', sans-serif"}>
                    {data?.Subtitulo}
                </Text>
                <Heading className={`${data?.ClasseCss}-titulo`} w='100%' mb={'15px'} fontSize={'50px'} letterSpacing={'-2px'} fontWeight={800} fontFamily={"'Plus Jakarta Sans', sans-serif"}>
                    {data?.Titulo}
                </Heading>
                <InnerHtml w='full' className={`${data?.ClasseCss}-subtitulo-descricao`} fontSize={'17px'} lineHeight={'29px'} pb={'10px'} fontWeight={500} html=
                    {data?.Descricao}
                />
                {data?.TextoLink &&
                    <Box mb='20px'>
                        <CustonButton className={`${data?.ClasseCss}-interno-botao`} href={data?.PaginaExt || data?.PaginaInt?.data?.attributes?.Slug} target={data?.PaginaExt ? "_blank" : ""}>{data?.TextoLink}</CustonButton>
                    </Box>
                }
            </VStack>
        )
    }

    return (
        <Box as={'section'}
            display={'flex'}
            flexWrap={'wrap'}
            minHeight={500}
            className={data?.ClasseCss}
            pos={'relative'}
            scrollBehavior={'smooth'}
            scrollSnapType={'block'}
            overflow={'hidden'}
            bg={'blackAlpha.100'}>

            <Modal size={'6xl'} isOpen={isOpen} onClose={onClose} >
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>
                        <Image alt="" src="https://dr7dcgej7t392.cloudfront.net/uploads/ico_youtube_391ebc60f4.webp" h={'29px'} display="flex" float="left" marginRight="10px" alignItems="center" />  {data?.Titulo}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody p={0}>
                        {data?.VideoLocal?.data &&
                            <video src={data?.VideoLocal?.data?.attributes?.url} controls width="100%" height="auto" />
                        }
                        {data?.VideoYoutubeUrl &&
                            <AspectRatio height={'100vh'}>
                                <iframe
                                    title={data?.Descricao}
                                    src={data?.VideoYoutubeUrl}
                                    allowFullScreen
                                />
                            </AspectRatio>
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Show above="sm">
                <Box
                    pos={'absolute'}
                    height={'100%'}
                    width={{ sm: '100%', md: '50%', lg: '50%', xl: '50%', '2xl': '50%' }}
                    {...{
                        [data?.Alinhamento === 'Esquerda' ? 'left' : 'right']: 0
                    }}
                    display={'flex'}
                    flexDirection={data?.Alinhamento === 'Esquerda' ? 'row' : 'row-reverse'} >
                    <Box pos={'relative'}>
                        <IconButton onClick={() => showVideoModal()}
                            color={'white'}
                            colorScheme="white"
                            pos={'absolute'}
                            fontSize={90}
                            top={'50%'}
                            left={'50%'}
                            transform={'translate(-50%, -50%)'}
                            variant={'ghost'}
                            aria-label="Play"
                            icon={<PlayIcon />}
                            size="lg" />
                        <Image
                            id="ConteudoVid1-image"
                            className="conteudo-vid1-imagem-fundo"
                            h={'100%'}
                            objectFit={'cover'}
                            src={data?.ImagemFundoVideo?.data?.attributes?.url}
                            alt={data?.alt} />
                    </Box>

                </Box>
            </Show>
            <Container maxW={containerBreakpoints} display={'flex'} flexWrap={'wrap'} flexDirection={data?.Alinhamento === 'Esquerda' ? 'row-reverse' : 'row'}>
                <Show below="md">
                    <Box pos={'relative'}>
                        <IconButton onClick={() => showVideoModal()}
                            color={'white'}
                            colorScheme="white"
                            pos={'absolute'}
                            fontSize={90}
                            top={'50%'}
                            left={'50%'}
                            transform={'translate(-50%, -50%)'}
                            variant={'ghost'}
                            aria-label="Play"
                            icon={<PlayIcon />}
                            size="lg" />
                        <Image
                            id="ConteudoVid1-image"
                            className="conteudo-vid1-imagem-fundo"
                            h={'100%'}
                            objectFit={'cover'}
                            src={data?.ImagemFundoVideo?.data?.attributes?.url}
                            alt={data?.alt} />
                    </Box>
                </Show>

                <Box width={{ sm: '100%', md: '50%', lg: '50%', xl: '50%', '2xl': '50%' }} h={'100%'}>
                    <TextComponent />
                </Box>
                {/* 
                <SimpleGrid flex={'0 0 auto'}
                    w={'100%'} pos={'relative'}
                    className={'SliderPortfolio1'}
                    columns={{
                        sm: 1,
                        md: 2,
                        lg: 2,
                        xl: 2,
                        "2xl": 2,
                    }} >
                    {data?.Alinhamento === 'Esquerda' ? <ImageComponent /> : <TextComponent />}
                    {data?.Alinhamento === 'Esquerda' ? <TextComponent /> : <ImageComponent />}
                </SimpleGrid>
                */}
            </Container>
        </Box>
    )
}

export default ConteudoVid1
