import { Text, VStack, Image, HStack, Box, Container } from "@chakra-ui/react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { containerBreakpoints } from "@/components/breakpoints"
library.add(fas)
const camelToDash = (str: string) => {
    return str ? str.replace(/([A-Z])/g, function ($1) { return "-" + $1.toLowerCase(); }) : '';
}
const ConteudoIconeItem = (props: any) => {
    const { data, alinhamento } = props
    
    const Center = () => {
        return (
            <VStack className="ConteudoIcone3-vstack" gap={2}>
                <Image src={data?.ImagemIcone?.data?.attributes?.url} alt={data?.ImagemIcone?.data?.attributes?.alternativeText} w={100} />
                <Text className="ConteudoIcone3-titulo" fontWeight={'bold'} fontSize={22}>{data?.Titulo}</Text>
                <Box className="ConteudoIcone3-descricao" fontSize={14}>
                    <span dangerouslySetInnerHTML={{__html:data?.Descricao}}></span>
                </Box>
            </VStack>
        )
    }

    const Left = () => {
        
        return (
            <HStack className="ConteudoIcone3-hstack" gap={2}>
                <Image src={data?.ImagemIcone?.data?.attributes?.url} alt={data?.ImagemIcone?.data?.attributes?.alternativeText} />
                <VStack className="ConteudoIcone3-vstack" gap={2}>
                    <Text className="ConteudoIcone3-titulo" fontWeight={'bold'} fontSize={22}>{data?.Titulo}</Text>
                    <Text className="ConteudoIcone3-descricao" fontSize={14}>{data?.Descricao}</Text>
                </VStack>
            </HStack>
        )
    }

    const Right = () => {
        
        return (
            <HStack className="ConteudoIcone3-hstack" gap={2}>
                <VStack className="ConteudoIcone3-vstack" gap={2}>
                    <Text className="ConteudoIcone3-titulo" fontWeight={'bold'} fontSize={22}>{data?.Titulo}</Text>
                    <Text className="ConteudoIcone3-descricao" fontSize={14}>{data?.Descricao}</Text>
                </VStack>
                <Image src={data?.ImagemIcone?.data?.attributes?.url} alt={data?.ImagemIcone?.data?.attributes?.alternativeText} />
            </HStack>
        )
    }

    return <>
        {alinhamento === 'Centro' && <Center />}
        {alinhamento === 'Esquerda' && <Left />}
        {alinhamento === 'Direita' && <Right />}
    </>
}

export default ConteudoIconeItem
