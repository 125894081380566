'use client'

import { Box, Container, HStack, VStack, Image, Text } from "@chakra-ui/react"
import InnerHtml from "./innerHtml"
import { containerBreakpoints } from "@/components/breakpoints"

const ConteudoBrasalNumeros = (props: any) => {
    const { data } = props
    const numeros = data.Numeros || []
    const css = {
        'WebkitTextFillColor': '#FFF',
        'WebkitTextStrokeWidth': '1px',
        'WebkitTextStrokeColor': '#cbcbcb'
    }
    const breackPoints = ['100%', '100%', '100%', '50%', '25%', '25%']

    return (
        <Box as={'section'} position={'relative'} padding={'68px 0 120px'}
            bg={{
                sm: `url(${data?.ImagemFundoMobile?.data?.attributes?.url}) no-repeat`,
                md: `url(${data?.ImagemFundoDesktop?.data?.attributes?.url}) no-repeat`,
            }}
            className={`${data?.ClasseCss}`}
            backgroundPosition={'top center!important'}
            borderTop={'1px solid RGBA(0, 0, 0,0.1)'}
            height={['auto', 'auto', 'auto', 'auto', '560px']}>
            <Container maxW={containerBreakpoints}>
                <HStack mt={1} rounded={0} p={0} m={0} bg={'transparent'} flexWrap={'wrap'} gap={0} rowGap={8}>
                    {numeros.map((item: any, index: number) => {
                        return (
                            <Box bg={'white'} paddingX={'15px'} key={'numero_' + index} width={breackPoints} background={'transparent'} className={`${data?.ClasseCss}-item`}>
                                <Box p={'26px 20px 60px'} shadow={'3px 7px 25px #eee'} >
                                    <VStack>
                                        <Image className={`${data?.ClasseCss}-icone`} alt={item?.Imagem?.data?.attributes?.alternativeText} w={154} src={item?.Imagem?.data?.attributes?.url} marginBottom={'7px'} />
                                        <HStack align={'end'} gap={0} marginBottom={'16px'}>
                                            <Text className={`${data?.ClasseCss}-numero`} fontSize={'120px'} color={'#5d5d5d'} fontWeight={800} letterSpacing={-1} lineHeight={1} fontFamily={"'Poppins', sans-serif"} css={css}>
                                                {item?.Numero}
                                            </Text>
                                            <Text className={`${data?.ClasseCss}-unidade`} fontSize={'44px'} fontWeight={800} fontFamily={"'Poppins', sans-serif"} css={css}>
                                                {item?.Unidade}
                                            </Text>
                                        </HStack>
                                        <InnerHtml className={`${data?.ClasseCss}-descricao`} p={0} fontSize={'18px'} fontWeight={500} m={'0 auto 0'} maxWidth={'254px'} textAlign={'center'} lineHeight={1.2} html={item?.Descricao} />
                                    </VStack>
                                </Box>
                            </Box>
                        )
                    }
                    )}
                </HStack>
            </Container>
            <Box background={'url(https://dr7dcgej7t392.cloudfront.net/uploads/bg_arco_2_8654f7ebbe.webp) no-repeat'} backgroundSize={'contain'} width={90} height={90} position={'absolute'} left={0} bottom={0} />
        </Box>
    )
}

export default ConteudoBrasalNumeros

