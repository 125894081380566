import { Box, Text } from "@chakra-ui/react"
import { useEffect, useRef } from "react"

const InnerHtml = (props:any) => {
    const { html } = props
    const ref:any = useRef(null)
    useEffect(() => {
        if (ref.current) {
            ref.current.innerHTML = html
        }
    })
    return (
        <Box as="span" ref={ref} {...props} />
    )
}

export default InnerHtml
