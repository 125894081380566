'use client'
import { containerBreakpoints } from '@/components/breakpoints'
import CustonButton from '@/components/button'
import { Box, HStack, Image, Heading, Fade, Container, Show } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import InnerHtml from './innerHtml'
import { BoxGray } from '@/components/boxes'

const ConteudoImgSlider = (props: any) => {
    const { data } = props
    
    const [current, setCurrent] = useState(0)
    const transition = { exit: { duration: 0.8 }, enter: { duration: 0.8 } }
    const h5Before = {
        position: 'relative',
        content: '""',
        width: '7px',
        height: '7px',
        display: 'block',
        backgroundColor: '#cc0007',
        borderRadius: '50%',
        marginBottom: '16px',
    }

    useEffect(() => {
        const interval = setInterval(() => moveSlider(1), 3000)
        return () => clearInterval(interval)
    },[current])

    const moveSlider = (direction: number) => {
        // todo: verificar o código para pegar o tamanho do array Slides
        // let nextPossition = direction > 0
        //     ? (current >= data?.Slides.length - 1 ? -1 : direction)
        //     : (current == 0 ? data?.Slides.length - 1 : direction)
        const length = data?.Slides?.length
        const nextPossition = direction > 0 
            ? (current == (length-1) ? -(current) : direction)
            : (current == 0 ? length-1 : direction)

        setCurrent(current + nextPossition)
    }

    const goToSlide = () => {
        if(data?.Slides[current]?.PaginaExterna) {
            return data?.Slides[current]?.PaginaExterna
        }
        if(data?.Slides[current]?.PaginaInterna?.data) {
            return data?.Slides[current]?.PaginaInterna?.data?.attributes?.url
        }
        return '#'
    }
    return (
        <Box as="section" pos={'relative'} bg={'rgb(247,247,247)'} className={data?.ClasseCss}>
            <Box
                pos={'absolute'}
                h={680} 
                w={{ sm: '100%', md: '50%', base: '100%' }}
                className="conteudo-img-slider-box"
                {...{
                    [data?.Slides[current]?.Alinhamento !== 'Esquerda' ? 'left' : 'right'] : 0
                }}
                >
                <BoxGray
                    zIndex={3}
                    w={'100px'}
                    h={'100px'}
                    top={10}
                    {...{
                        [data?.Slides[current]?.Alinhamento !== 'Esquerda' ? 'left' : 'right'] : 0
                    }}
                    className="conteudo-img-slider-circle"
                    border={'15px solid #eee'}
                    backgroundColor={'#f7f7f7'} />
                {data?.Slides.map((item: any, index: number) => {
                    return (
                        <Fade key={item?.id} in={parseInt(current.toString(), 10) == index} style={{
                            height: '100%',
                            position: 'absolute',
                            [item?.Alinhamento !== 'Esquerda' ? 'left' : 'right'] : 0,
                        }} transition={transition}>
                            <Show above={'lg'}>
                                <Image h={'100%'} w={'100%'} objectFit={'cover'} alt={item?.desc} src={item?.ImagemDesktop?.data?.attributes?.url} />
                            </Show>
                            <Show below={'lg'}>
                                <Image h={'100%'} w={'100%'} objectFit={'cover'} alt={item?.desc} src={item?.ImagemMobile?.data?.attributes?.url} />
                            </Show>
                        </Fade>
                    )
                })}
            </Box>
            <Container minW={containerBreakpoints} >
                <HStack flexWrap={'wrap'} gap={0} flexDirection={data?.Slides[current]?.Alinhamento == 'Esquerda' ? 'row' : 'row-reverse'}>

                    <Box w={{ sm: '100%', md: '50%', base: '100%' }} h={'680'} className='container-slider-conteudo'>
                        <Box marginBottom={'50px'} marginTop={30}>
                            <Heading className={`${data?.ClasseCss}-subtitulo`} as="h5" textTransform={'uppercase'} fontSize={'14px'} letterSpacing={'1.4px'} lineHeight={'1.2'} fontWeight={'500'} margin={'0 0 7px'} position={'relative'} color={'#c4c4c4'} fontFamily={"'Plus Jakarta Sans', sans-serif"} _before={h5Before}>
                                {data?.Subtitulo}
                            </Heading>
                            <Heading className={`${data?.ClasseCss}-titulo`} fontSize={'77px'} letterSpacing={'-2px'} lineHeight={'68px'} margin={'0'} fontWeight={800} fontFamily={"'Plus Jakarta Sans', sans-serif"}>
                                {data?.Slides[current]?.Titulo}
                            </Heading>
                        </Box>
                        <InnerHtml className={`${data?.ClasseCss}-descricao `} html={data?.Slides[current]?.Descricao} color={'#333'} fontSize={'17px'} marginTop={0} marginBottom={'1rem'} lineHeight={'1.875'} fontWeight={500} />
                        {data?.Slides[current]?.TextoLink &&
                            <CustonButton href={goToSlide()} className={`${data?.ClasseCss}-botao`} >{data?.Slides[current]?.TextoLink}</CustonButton>
                        }
                        <HStack alignSelf={'flex-start'} py={10}>
                            <Box rounded={'full'} w={'56px'} h={'56px'} border={'2px solid #808080'} display={'flex'} justifyContent={'center'} alignItems={'center'} onClick={() => moveSlider(-1)} cursor={'pointer'}>
                                <Box as="span" fontSize={'20px'} fontWeight={500} color={'#808080'}>&lt;</Box>
                            </Box>
                            <Box rounded={'full'} w={'56px'} h={'56px'} border={'2px solid #808080'} display={'flex'} justifyContent={'center'} alignItems={'center'} onClick={() => moveSlider(1)} cursor={'pointer'}>
                                <Box as="span" fontSize={'20px'} fontWeight={500} color={'#808080'}>&gt;</Box>
                            </Box>
                        </HStack>
                    </Box>
                </HStack>
            </Container>
        </Box>
    )
}

export default ConteudoImgSlider
