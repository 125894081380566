'use client'
import { Box, Heading, keyframes } from "@chakra-ui/react"

const BannerComponent = (props: any) => {
  const { data } = props
  if (!data) return (null)

  const textScrolling = keyframes`
    from { transform: translateX(0%);}
    to {transform: translateX(-100%);}
  `

  const getData = () => {
    let newData:any[] = []
    for(let counter = 0; counter < 10; counter++) {
      newData = [...data, ...newData]
    }
    return newData
  }


  return (
    <Box as="section" className="nuvem-palavras" position={'relative'} display={'block'} backgroundColor={'#cc0007'} overflow={'hidden'}>
      <Box position={'relative'} display={'block'} padding={'21px 0 9px'} whiteSpace={'nowrap'}>
        <Heading as={'ul'}
          position={'relative'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          willChange={'transform'}
          flexWrap={'nowrap'}
          animation={`${textScrolling} 19s linear infinite`}
          whiteSpace={'nowrap'}
          paddingLeft={'0'}
          marginTop={'0'}
          marginBottom={'1rem'}
           
          fontSize={22}
          color={'white'}
          _hover={{animationPlayState: 'paused'}}>
          {getData().map((item: any, index:number) => {
            return (
              <Box as={'li'} 
                px={2} 
                display={'flex'} 
                position={'relative'} 
                alignItems={'center'} 
                fontSize={'20px'}
                letterSpacing={'2px'} 
                fontWeight={600} 
                textTransform={'uppercase'} 
                lineHeight={1} 
                margin={'0 20px'} 
                key={`banner-item-${item?.id}-${index}`}>
                {item?.Palavra}
              </Box>
            )
          }
          )}
        </Heading>
      </Box>
    </Box>
  )
}

export default BannerComponent
