import { containerBreakpoints } from "@/components/breakpoints"
import { Box, Text, Heading, Container, Tabs, TabList, TabPanels, TabPanel, Tab } from "@chakra-ui/react"
import InnerHtml from "./innerHtml"
import ConteudoTexto1 from "./ConteudoTexto1"
import ConteudoInternoAba from "./ConteudoInternoAba"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


const pascalToSnakeCase = (s: string): any => {
    const value = s.replace(/(?:^|\.?)([A-Z])/g, function (x, y) { return "-" + y.toLowerCase() })
        .replace(/^_/, "")
        .replace("-fa-", "")
    if (value == 'gulp')
        return 'car'
    return value
}

const ConteudoAba = (props: any) => {
    const { data } = props

    const before = {
        position: 'absolute',
        top: 0,
        content: '""',
        width: '7px',
        height: '7px',
        display: 'block',
        backgroundColor: '#cc0007',
        borderRadius: '50%',
        marginBottom: '16px'
    }
    const beforeCenter = { ...before, ...{ left: '50%' } }
    const beforeLeft = { ...before, ...{ left: 0 } }
    const beforeRight = { ...before, ...{ right: 0 } }

    const getSubtitleDecoration = () => {
        if (data.Alinhamento === 'Direita') {
            return beforeRight
        }
        if (data.Alinhamento === 'Centro') {
            return beforeCenter
        }
        return beforeLeft
    }

    const getTextAlign = () => {
        if (data.Alinhamento === 'Direta') {
            return 'end'
        }
        if (data.Alinhamento === 'Centro') {
            return 'center'
        }
        return 'start'
    }

    return (
        <Box as="section" className={data?.ClasseCss} pt={10}>
            <Container maxW={containerBreakpoints}>
                {data?.Subtitulo &&
                    <Text className={`${data?.ClasseCss}-subtitulo`} as='h5' _before={getSubtitleDecoration()} pos='relative' pt={'16px'} textAlign={getTextAlign()} w={'100%'} fontSize={'14px'} lineHeight={1.2} textTransform={'uppercase'} letterSpacing={'1.4px'} fontWeight={'500'} margin={'0 0 7px'} fontFamily={"'Plus Jakarta Sans', sans-serif"}>
                        {data?.Subtitulo}
                    </Text>
                }
                {data?.Titulo &&
                    <Heading className={`${data?.ClasseCss}-titulo`} mb={10} fontSize={'50px'} letterSpacing={'-2px'} textAlign={getTextAlign()} fontWeight={800} fontFamily={"'Plus Jakarta Sans', sans-serif"}>
                        {data?.Titulo}
                    </Heading>
                }
                <InnerHtml className={`${data?.ClasseCss}-descricao`} fontSize={'17px'} lineHeight={'29px'} pb={'10px'} fontWeight={500} textAlign={getTextAlign()} html={data?.Descricao} />
                <Tabs variant='soft-rounded' colorScheme="red" >
                    <TabList justifyContent={'center'} bgColor={'gray.100'} rounded={'full'} p={'10px'} overflow={'auto'}>
                        {data?.Abas.map((aba: any) => {
                            return <Tab key={`conteudo-aba-tab-${aba?.id}`}>
                                <FontAwesomeIcon icon={pascalToSnakeCase(aba?.IconeAba)} />
                                {aba?.TextoAba}
                            </Tab>
                        })}
                    </TabList>
                    <TabPanels>
                        {data?.Abas.map((aba: any) => {
                            return (
                                <TabPanel key={`conteudo-aba-panel-${aba?.id}`}>
                                    <ConteudoInternoAba data={aba} parentClass={data?.ClasseCss} />
                                </TabPanel>
                            )
                        })}
                    </TabPanels>
                </Tabs>
            </Container>
        </Box>
    )
}

export default ConteudoAba