"use client"

import { keyframes, Box } from '@chakra-ui/react'

const BoxGray = (props:any) => {
    const scalings = keyframes`
        from { transform: scale(1) }
        to { transform: scale(0.8) }
    `
    
    return <Box 
        position={'absolute'} 
        w={'190px'} 
        h={'190px'} 
        background={'#fff'} 
        border={'30px solid #f1f1f1'} 
        borderRadius={'50%'} 
        top={'-31px'} 
        left={'-31px'} 
        zIndex={-1}
        animation={`${scalings} 1.5s alternate infinite`}
        {...props}
        />
}

const BoxRed = () => {
    const scalings = keyframes`
    0% { transform: translate(0px, 0px) rotate(0deg) scale(1); }
    20% { transform: translate(73px, -1px) rotate(36deg) scale(0.9); }
    40% { transform: translate(141px, 72px) rotate(72deg) scale(1); }
    60% { transform: translate(83px, 122px) rotate(108deg) scale(1.2);}
    80% { transform: translate(-40px, 72px) rotate(144deg) scale(1.1); }
    100% { transform: translate(0px, 0px) rotate(0deg) scale(1);}
    `

    return <Box 
        position={'absolute'} 
        w={'56px'} 
        h={'56px'} 
        background={'#fff'} 
        border={'10px solid #f1f1f1'} 
        borderRadius={'50%'} 
        bottom={43}
        left={-76} 
        zIndex={-1}
        animation={`${scalings} 20s infinite linear`}
        />
}

export { BoxGray, BoxRed }